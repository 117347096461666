import { getContract, isInVestingPool, skynityConfig } from 'helpers';
import { VestingPoolConfig, VestingPoolData } from 'models';
import { concatMap, from, map, Observable, toArray } from 'rxjs';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

export class VestingService {
  public static getVestingPools(
    networkId: string,
    connectedAddress: string,
    web3: Web3,
    gasPrice: string
  ): Observable<VestingPoolData[]> {
    const vestingPoolsData: VestingPoolData[] = skynityConfig.vesting.pools.map(
      (vestingPool: VestingPoolConfig) => {
        const vestingPoolContractInstance: Contract = getContract(
          web3,
          vestingPool.vestingContractJSON,
          networkId,
          gasPrice
        );

        return {
          poolName: vestingPool.poolName,
          depositToGameEnabled: vestingPool.depositToGameEnabled,
          vestingPoolContract: vestingPoolContractInstance,
        };
      }
    );

    return from(vestingPoolsData).pipe(
      concatMap(async (vestingPoolData) => {
        const inVestingPool = await isInVestingPool(
          connectedAddress,
          vestingPoolData.vestingPoolContract
        );

        return inVestingPool ? vestingPoolData : null;
      }),
      toArray(),
      map((data) => data.filter((item) => item !== null))
    );
  }
}
