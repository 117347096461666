import { Message, SectionHeader } from 'components';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectBalances,
  selectConnectedAddress,
  selectStablecoinPrice,
} from 'store';

import { MessageType, skynityConfig } from '../../helpers';
import StakingPools from './StakingPools';

export const Staking: React.FC = () => {
  const stableTokenPrice = useSelector(selectStablecoinPrice);
  const connectedAddress = useSelector(selectConnectedAddress);
  const balances = useSelector(selectBalances);

  // const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <>
      {connectedAddress ? (
        <div className="container staking">
          <div className="container-inner">
            <div className="section-container">
              <SectionHeader title="Staking">
                {/* TODO: improve descriptions when SAS staking is available */}
                {/* <div className="mb-2">
                  Stake SDT tokens or LP (Liquidity Provider) tokens in one of
                  the below staking pools to earn rewards.
                </div>
                <div className="mb-2">
                  Staking LP tokens generally offers higher rewards than SDT
                  tokens and they can be obtained by providing liquidity for
                  SDT-USDC pair on Uniswap decentralized exchange.{' '}
                  <a
                    className="link link-primary pointer"
                    onClick={(): void => setShowModal(true)}
                  >
                    Read more...
                  </a>
                </div> */}
                <div className="mb-2">
                  Stake LP (Liquidity Provider) tokens in one of the below
                  staking pools to earn rewards.
                </div>
                <Message messageType={MessageType.Primary}>
                  <div>
                    To provide liquidity and obtain LP tokens for staking:
                  </div>
                  <ul className="mb-0">
                    <li>
                      Make sure that you have a desired amount of SDT and USDC
                      tokens in your wallet (you need an equal dollar value of
                      both tokens to provide liquidity). You can buy SDT tokens
                      on{' '}
                      <a
                        href={skynityConfig.additionalData.tokenLink}
                        target="_blank"
                        rel="noreferrer"
                        className="link link-primary link-external"
                      >
                        Uniswap
                      </a>{' '}
                      or{' '}
                      <a
                        href={skynityConfig.additionalData.tokenLinkCEX}
                        target="_blank"
                        rel="noreferrer"
                        className="link link-primary link-external"
                      >
                        MEXC
                      </a>
                      .
                    </li>
                    <li>
                      Go to Uniswap{' '}
                      <a
                        href={skynityConfig.additionalData.lpPairLink}
                        target="_blank"
                        rel="noreferrer"
                        className="link link-primary link-external"
                      >
                        SDT-USDC LP pair
                      </a>{' '}
                      page.
                    </li>
                    <li>
                      Click on &quot;Add Liquidity&quot; in the upper right
                      portion of the page.
                    </li>
                    <li>
                      Click on &quot;Continue&quot; and proceed with the further
                      instructions.
                    </li>
                  </ul>
                  <div>
                    After completing the above process, you will receive an
                    appropriate amount of Uniswap SDT-USDC LP (UNI-V2) tokens in
                    your wallet.
                  </div>
                  <div>
                    More information can be found{' '}
                    <a
                      href="https://support.uniswap.org/hc/en-us/articles/20915582427917-How-to-add-liquidity-to-Uniswap-v2"
                      target="_blank"
                      rel="noreferrer"
                      className="link link-primary link-external"
                    >
                      here
                    </a>
                    .
                  </div>
                </Message>
              </SectionHeader>
            </div>

            {stableTokenPrice && balances && <StakingPools />}
          </div>
        </div>
      ) : (
        ''
      )}
      {/* <Modal show={showModal} backdrop="static" centered>
        <Modal.Header className="justify-center">
          Getting LP tokens
        </Modal.Header>
        <Modal.Body className="text-break">
          To provide liquidity and obtain LP tokens:
          <ul className="mb-0">
            <li>
              Make sure that you have a desired amount of SDT and USDC tokens in
              your wallet (you need an equal dollar value of both tokens to
              provide liquidity). You can buy SDT tokens on{' '}
              <a
                href={skynityConfig.additionalData.tokenLink}
                target="_blank"
                rel="noreferrer"
                className="link link-primary link-external"
              >
                Uniswap
              </a>{' '}
              or{' '}
              <a
                href={skynityConfig.additionalData.tokenLinkCEX}
                target="_blank"
                rel="noreferrer"
                className="link link-primary link-external"
              >
                MEXC
              </a>
              .
            </li>
            <li>
              Go to Uniswap{' '}
              <a
                href={skynityConfig.additionalData.lpPairLink}
                target="_blank"
                rel="noreferrer"
                className="link link-primary link-external"
              >
                SDT-USDC LP pair
              </a>{' '}
              page.
            </li>
            <li>
              Click on &quot;Add Liquidity&quot; in the upper right portion of
              the page.
            </li>
            <li>
              Click on &quot;Continue&quot; and proceed with the further
              instructions.
            </li>
          </ul>
          More information can be found{' '}
          <a
            href="https://support.uniswap.org/hc/en-us/articles/20915582427917-How-to-add-liquidity-to-Uniswap-v2"
            target="_blank"
            rel="noreferrer"
            className="link link-primary link-external"
          >
            here
          </a>
          .
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary w-100"
            onClick={(): void => setShowModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};
