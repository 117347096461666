/* eslint-disable complexity */
import classNames from 'classnames';
import {
  ButtonWithSpinner,
  CreateGameAccountModal,
  DataItem,
  GameAccountItem,
  SectionHeader,
} from 'components';
import { Message } from 'components/Message';
import { MessageText, MessageType, skynityConfig } from 'helpers';
import { getGameBridgeData, postWithdrawToWallet } from 'helpers/game-bridge';
import { partition } from 'lodash';
import {
  GameAccount,
  GameBridgeAccountData,
  GameServer,
  MainTokenSymbol,
  Nft,
  TokensData,
} from 'models';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  selectConnectedAddress,
  selectGameBridgeContract,
  selectIsSelectedGameServerFinished,
  selectSelectedGameServer,
  selectTokensData,
} from 'store';
import { Contract } from 'web3-eth-contract';

export interface GameAccountsProps {
  gameAccounts: GameAccount[];
  gameServers: GameServer[];
  nfts: Nft[];
  error: string;
}

export const GameAccounts: React.FC<GameAccountsProps> = ({
  gameAccounts,
  gameServers,
  nfts,
  error,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [gameBridgeAccountData, setGameBridgeAccountData] =
    useState<GameBridgeAccountData>(null);
  const connectedAddress: string = useSelector(selectConnectedAddress);
  const tokensData: TokensData = useSelector(selectTokensData);
  const gameBridgeContract: Contract = useSelector(selectGameBridgeContract);
  const selectedGameServer: GameServer = useSelector(selectSelectedGameServer);
  const isSelectedGameServerFinished: boolean = useSelector(
    selectIsSelectedGameServerFinished
  );
  const [accountsWithNFT, accountsWithoutNFT] = partition(
    gameAccounts,
    (acc: GameAccount) => acc.nft_id > 0
  );
  const gameAccountsSorted = [...accountsWithNFT, ...accountsWithoutNFT];
  const gameAccountItems = gameAccountsSorted?.map(
    (account: GameAccount, index: number) => {
      const assignedNft = nfts?.find((nft) => nft.edition === account.nft_id);
      return (
        <GameAccountItem
          key={`account-item-${index}`}
          assignedNft={assignedNft}
          index={index}
          account={account}
          gameBridgeAccountData={gameBridgeAccountData}
          onUpdateData={(): Promise<void> => getGameBridgeAccountData()}
        ></GameAccountItem>
      );
    }
  );
  const canCreateAccount = nfts?.length > 0;

  useEffect(() => {
    getGameBridgeAccountData();
  });

  const getGameBridgeAccountData = async (): Promise<void> => {
    try {
      const payload = await getGameBridgeData(
        connectedAddress,
        tokensData.SDT,
        gameBridgeContract,
        selectedGameServer.id
      );

      setGameBridgeAccountData(payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const onWithdrawToWallet = async (): Promise<void> => {
    setLoading(true);
    setErrorMessage('');

    try {
      await postWithdrawToWallet(
        gameBridgeContract,
        connectedAddress,
        selectedGameServer.id
      );
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setErrorMessage((error as any).message);
    }

    setLoading(false);
  };

  return (
    <div className="section-container">
      <div className="game-accounts">
        <div className={isSelectedGameServerFinished ? '' : 'mb-32'}>
          {/* <SectionHeader
          title="Wallet"
          description="Your pending and accepted SDT withdrawals from game accounts."
        /> */}

          <div className="flex-container justify-between wrap">
            <DataItem
              label={'Pending withdrawals'}
              data={gameBridgeAccountData?.pendingWithdrawal}
              tooltipHint="Total amount of SDT tokens that were requested for withdrawal from all your accounts on this server and are currently waiting to be approved. For security reasons please allow up to 24 hours before the tokens are ready to be withdrawn to your wallet."
              symbol={MainTokenSymbol.SDT}
              iconClasses={'fa fa-hourglass'}
            />

            {/* TODO: Update icon */}
            <DataItem
              label={'Ready for withdraw'}
              data={gameBridgeAccountData?.approvedWithdrawal}
              tooltipHint="Total amount of SDT tokens requested for withdrawal from all your accounts on this server that have already been approved for withdrawal to your wallet. You can withdraw them to your wallet at any time."
              symbol={MainTokenSymbol.SDT}
              iconClasses={'fas fa-arrow-right-from-bracket'}
            />

            <ButtonWithSpinner
              // text="Withdraw to wallet"
              classes="btn btn-primary nowrap px-3"
              onClick={(): void => {
                onWithdrawToWallet();
              }}
              disabled={!+gameBridgeAccountData?.approvedWithdrawal}
              loading={loading}
            >
              <i className="fas fa-arrow-right-from-bracket pointer mr-8" />
              Withdraw to wallet
            </ButtonWithSpinner>
          </div>
          {errorMessage && (
            <Message
              descriptionText={
                errorMessage?.includes('404')
                  ? `${errorMessage} ${MessageText.Fees}`
                  : errorMessage
              }
              messageType={MessageType.Error}
            />
          )}
        </div>

        {isSelectedGameServerFinished ? (
          ''
        ) : (
          <>
            <SectionHeader
              title="Game accounts"
              description="Manage existing game accounts and create new."
            />

            <Accordion defaultActiveKey="0">
              {gameAccountItems && gameBridgeAccountData ? (
                <div>{gameAccountItems}</div>
              ) : (
                ''
              )}
            </Accordion>

            <button
              className={classNames(
                'btn nowrap mt-32 w-100',
                gameAccounts?.length ? 'btn-secondary' : 'btn-primary'
              )}
              disabled={!canCreateAccount}
              onClick={(): void => setShowModal(true)}
            >
              <i className="fa fa-plus mr-8" />
              Create new account
            </button>

            {canCreateAccount ? (
              ''
            ) : (
              <Message messageType={MessageType.Primary}>
                You cannot create a new account due to lack of unassigned
                SkyLands NFTs on your wallet. If you have NFTs previously
                purchased on BNB Smart Chain network you can bridge them{' '}
                <a
                  href={skynityConfig.additionalData.skyLandsNftBridgeLink}
                  target="_blank"
                  className="link primary"
                  rel="noreferrer"
                >
                  here
                </a>
                . You can also purchase new NFTs{' '}
                <a
                  href={skynityConfig.additionalData.skyLandsNftMarketplaceLink}
                  target="_blank"
                  className="link primary"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </Message>
            )}
          </>
        )}
      </div>

      {showModal ? (
        <CreateGameAccountModal
          gameAccounts={gameAccounts}
          gameServers={gameServers}
          nfts={nfts}
          error={error}
          hideModal={(): void => setShowModal(false)}
        ></CreateGameAccountModal>
      ) : (
        ''
      )}
    </div>
  );
};
