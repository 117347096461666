import classNames from 'classnames';
import { ButtonWithSpinner } from 'components/ButtonWithSpinner';
import { Message } from 'components/Message';
import { SectionHeader } from 'components/SectionHeader/SectionHeader';
import { isProduction, MessageType } from 'helpers';
import { GameServer, GameServerStatus } from 'models';
import React, { useEffect } from 'react';
import { ListGroup, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGameServers,
  selectGameServers,
  selectSelectedGameServer,
} from 'store';

export interface GameSettingsLoginProps {
  loading: boolean;
  error: string;
  onLogin: () => void;
  onSelectGameServer: (gameServer: GameServer) => void;
}

export const GameSettingsLogin: React.FC<GameSettingsLoginProps> = ({
  loading,
  error,
  onLogin,
  onSelectGameServer,
}) => {
  const dispatch = useDispatch();
  const gameServers: GameServer[] = useSelector(selectGameServers);
  const selectedGameServer = useSelector(selectSelectedGameServer);
  const serverStatusOrder = Object.values(GameServerStatus);
  const gameServersListSort = (a: GameServer, b: GameServer): number => {
    const statusDiff =
      serverStatusOrder.indexOf(a.status) - serverStatusOrder.indexOf(b.status);

    if (statusDiff !== 0) {
      return statusDiff;
    }

    const prevAccounts = a.stats.wallet_stats?.accounts || 0;
    const nextAccounts = b.stats.wallet_stats?.accounts || 0;

    if (prevAccounts > 0 && nextAccounts > 0) {
      return nextAccounts - prevAccounts; // sort by accounts descending
    } else if (prevAccounts > 0) {
      return -1;
    } else if (nextAccounts > 0) {
      return 1;
    } else {
      // both have no accounts, sort by players descending
      return b.stats.players - a.stats.players;
    }
  };

  const gameServersList = gameServers && (
    <ListGroup className="mb-16">
      {gameServers
        ?.slice()
        ?.sort((a, b) => gameServersListSort(a, b))
        ?.map((gameServer: GameServer, index) => {
          const disabled =
            gameServer.status === GameServerStatus.Offline ||
            !!gameServer.stats.error;

          return (
            <ListGroup.Item
              action
              key={index}
              onClick={(): void => onSelectGameServer(gameServer)}
              disabled={disabled}
              active={gameServer.id === selectedGameServer?.id}
              style={{ height: 'auto', minHeight: '45px' }}
            >
              <div
                className={
                  'flex-container align-items-center justify-between gap-1 flex-column flex-sm-row'
                }
              >
                <div className="wrap">
                  {!disabled && (
                    <i
                      className={classNames({
                        fas: true,
                        'fa-circle': true,
                        'text-success':
                          gameServer.status === GameServerStatus.Online,
                        'mr-8': true,
                      })}
                      title={gameServer.status}
                    ></i>
                  )}
                  {gameServer.name}
                </div>
                <div className="nowrap"></div>

                <div className="flex-container align-items-center flex-nowrap gap-2 nowrap text-center">
                  {!isProduction() && (
                    <OverlayTrigger overlay={<Tooltip>Players online</Tooltip>}>
                      <span title="Players online">
                        <i className="fa fa-globe me-1" />
                        {gameServer.stats.players_online ?? '?'}{' '}
                        <small className="text-secondary">[DEV_ONLY]</small>
                      </span>
                    </OverlayTrigger>
                  )}

                  {gameServer.status === GameServerStatus.Finished ? (
                    ''
                  ) : (
                    <OverlayTrigger
                      overlay={<Tooltip>Total players / Max players</Tooltip>}
                    >
                      <span title="Total players">
                        <i className="fa fa-users me-1" />
                        {gameServer.stats.players ?? '?'} /{' '}
                        {gameServer.players_limit}
                      </span>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
            </ListGroup.Item>
          );
        })}
    </ListGroup>
  );

  useEffect(() => {
    dispatch(getGameServers());
  }, []);

  return (
    <div className="section-container" key={`${loading}`}>
      <SectionHeader
        title="Game panel login"
        description="Select a desired server from the below list of available servers and login with your wallet."
      />

      <>
        {gameServers ? (
          <>
            <div className="mb-8 bold text-background-gold">
              Available servers
            </div>

            {gameServers.length > 0 ? (
              <>
                {gameServersList}

                <ButtonWithSpinner
                  text="Login"
                  classes="btn btn-primary w-100"
                  onClick={(): void => onLogin()}
                  loading={loading}
                  disabled={loading || !selectedGameServer}
                />
              </>
            ) : (
              <Message messageType={MessageType.Primary}>
                {/* TODO: update after game starts */}
                <div>No active game servers available at the moment.</div>
                <div>
                  Maps with real SDT tokens will start on 20.02.2025 - stay
                  tuned on{' '}
                  <a
                    href="https://twitter.com/SkyNity_io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    X
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://discord.gg/skynity"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Discord
                  </a>{' '}
                  for more info.
                </div>
              </Message>
            )}
          </>
        ) : (
          <div className="mb-16 flex-container align-center justify-center">
            <Spinner
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </div>
        )}
      </>

      {error ? (
        <Message
          messageType={MessageType.Error}
          descriptionText={error}
        ></Message>
      ) : (
        ''
      )}
    </div>
  );
};
