import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { skynityConfig } from '../helpers';

export const BuyButton: React.FC<{ className?: string }> = ({ className }) => (
  <OverlayTrigger
    trigger="focus"
    placement="bottom"
    overlay={
      <Tooltip>
        {' '}
        <a
          href={skynityConfig.additionalData.tokenLink}
          target="_blank"
          rel="noreferrer"
          className="link primary link-external d-block"
        >
          Buy on Uniswap
        </a>
        <a
          href={skynityConfig.additionalData.tokenLinkCEX}
          target="_blank"
          rel="noreferrer"
          className="link primary link-external d-block"
        >
          Buy on MEXC (no KYC)
        </a>
      </Tooltip>
    }
  >
    <a
      href="#"
      onClick={(e): void => {
        e.preventDefault();
      }}
      title="Buy"
      className={`link fas fa-cart-shopping ${className}`}
      style={{ fontWeight: '900' }}
    >
      <i className="fa fa-caret-down" />
    </a>
  </OverlayTrigger>
);

export default BuyButton;
