export interface GameServerStats {
  players?: number;
  players_online?: number;
  wallet_stats?: {
    wallet: string;
    accounts: number;
    assigned_nfts: number[];
  };
  error?: unknown;
}
export interface GameServer {
  id: number;
  group: string;
  address: string;
  name: string;
  web3: number;
  status: GameServerStatus;
  players_limit: number;
  season_start: number;
  season_finish: number;
  created: null;
  stats: GameServerStats;
}

export interface GetGameServersResponse {
  servers: GameServer[];
}

//* Keep order
export enum GameServerStatus {
  Online = 'online',
  Finished = 'finished',
  Offline = 'offline',
}
