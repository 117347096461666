import { NetworksParams } from 'config/networks';
import { isProduction } from 'helpers/environment.helper';
import {
  AllocationPoolConfig,
  AllocationPoolName,
  AllocationPoolSubtype,
  AllocationPoolType,
  AppConfig,
  StakingPoolConfig,
  StakingPoolItemName,
  StakingPoolItemType,
  StakingPoolName,
  StakingPoolType,
  TokenName,
  VestingPoolConfig,
} from 'models';
import {
  LPTokenSymbol,
  MainTokenSymbol,
  StableTokenSymbol,
} from 'models/token.model';

// ALLOCATION
import skynityAllocationPrivateUSDCContractJSON from './contracts/allocation/SkynityAllocationVaultPrivateUSDC.json';
import skynityAllocationPublicUSDCContractJSON from './contracts/allocation/SkynityAllocationVaultPublicUSDC.json';
// GAME BRIDGE
import gameBridgeContractJSON from './contracts/game-bridge/GameBridge.json';
// LP
import SDTUSDCLPPairContractJSON from './contracts/lp/LPPair.json';
import SDTUSDCtokenLPContractJSON from './contracts/lp/LPToken.json';
// NFT
import NFTContactJSON from './contracts/nft/NFTContract.json';
// TOKEN
import SDTtokenContractJSON from './contracts/sdt/SDTToken.json';
// STABLECOIN
import SCUSDCTokenJSON from './contracts/stable/SCUSDCToken.json';
// STAKING
import StakingLPSDTUSDCLiquid from './contracts/staking/liquid/Staking_LP_Liquid.json';
// import StakingSDTLiquid from './contracts/staking/liquid/Staking_Sdt_Liquid.json';
// import StakingLPSDTUSDCLocked3M from './contracts/staking/locked/Staking_SdtUsdc_Locked3m.json';
// import StakingLPSDTUSDCLocked6M from './contracts/staking/locked/Staking_SdtUsdc_Locked6m.json';
// VESTING
import vestingAirdropRewards from './contracts/vesting/VestingAirdropRewards.json';
import vestingIngame from './contracts/vesting/VestingIngame.json';
import vestingL1 from './contracts/vesting/VestingL1.json';
import vestingM1 from './contracts/vesting/VestingM1.json';
import vestingM3 from './contracts/vesting/VestingM3.json';
import vestingM4 from './contracts/vesting/VestingM4.json';
import vestingPresale from './contracts/vesting/VestingPresale.json';
import vestingS1 from './contracts/vesting/VestingS1.json';
import vestingS2 from './contracts/vesting/VestingS2.json';
import vestingT from './contracts/vesting/VestingT.json';

const permittedNetworks = [
  NetworksParams.BaseMainnet,
  ...(isProduction() ? [] : [NetworksParams.BaseTestnet]),
];

const primaryNetwork = NetworksParams.BaseMainnet;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LPStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.LP,
  // TODO: Update name, change contracts
  poolDescription: 'SDT-USDC',
  poolName: StakingPoolName.LP,
  poolStakingTokenSymbol: LPTokenSymbol.SDTUSDC,
  poolItems: [
    {
      poolItemName: StakingPoolItemName.Liquid,
      poolItemtype: StakingPoolItemType.Bonded,
      stakingContractJSON: StakingLPSDTUSDCLiquid,
    },
    // {
    //   poolItemName: '#DURATION# Locked',
    //   poolItemtype: StakingPoolItemType.Locked,
    //   stakingContractJSON: StakingLPSDTUSDCLocked3M,
    // },
    // {
    //   poolItemName: '#DURATION# Locked',
    //   poolItemtype: StakingPoolItemType.Locked,
    //   stakingContractJSON: StakingLPSDTUSDCLocked6M,
    // },
  ],
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SAStakingPool: StakingPoolConfig = {
  poolType: StakingPoolType.SA,
  poolDescription: 'SDT',
  poolName: StakingPoolName.SA,
  poolStakingTokenSymbol: MainTokenSymbol.SDT,
  poolItems: [
    // {
    //   poolItemName: StakingPoolItemName.Liquid,
    //   poolItemtype: StakingPoolItemType.Bonded,
    //   stakingContractJSON: StakingSDTLiquid,
    // },
    // {
    //   poolItemName: StakingPoolItemName.ThreeMonths,
    //   poolItemtype: StakingPoolItemType.Locked,
    //   stakingContractJSON: StakingSDTLocked3M,
    //   lockPeriod: 3,
    // },
  ],
};

const pregamePublicAllocationPoolUSDC: AllocationPoolConfig = {
  poolType: AllocationPoolType.SC,
  poolSubtype: AllocationPoolSubtype.Public,
  poolDescription: 'USDC',
  poolName: AllocationPoolName.SCAllocation,
  poolTokenSymbol: StableTokenSymbol.USDC,
  poolItems: [
    {
      poolItemName: 'SkyNity pre-game $SDT public sale allocation (USDC)',
      poolItemContractJSON: skynityAllocationPublicUSDCContractJSON,
      hasWhitelist: false,
      isActive: false,
    },
  ],
};

const pregamePrivateAllocationPoolUSDC: AllocationPoolConfig = {
  poolType: AllocationPoolType.SC,
  poolSubtype: AllocationPoolSubtype.Private,
  poolDescription: 'USDC',
  poolName: AllocationPoolName.SCAllocation,
  poolTokenSymbol: StableTokenSymbol.USDC,
  poolItems: [
    {
      poolItemName:
        'SkyNity pre-game $SDT private sale allocation (1 SDT @ 0.04 USDC)',
      poolItemContractJSON: skynityAllocationPrivateUSDCContractJSON,
      hasWhitelist: true,
      isActive: true,
    },
  ],
};

const activeStakingPools: StakingPoolConfig[] = [
  LPStakingPool,
  // SAStakingPool
];
// TODO - change types
const activeVestingPools: VestingPoolConfig[] = [
  {
    poolName: 'Airdrop & rewards',
    depositToGameEnabled: true,
    vestingContractJSON: vestingAirdropRewards,
  },
  {
    poolName: 'Presale',
    depositToGameEnabled: true,
    vestingContractJSON: vestingPresale,
  },
  {
    poolName: 'M1',
    depositToGameEnabled: true,
    vestingContractJSON: vestingM1,
  },
  {
    poolName: 'M3',
    depositToGameEnabled: true,
    vestingContractJSON: vestingM3,
  },
  {
    poolName: 'M4',
    depositToGameEnabled: true,
    vestingContractJSON: vestingM4,
  },
  {
    poolName: 'L1',
    depositToGameEnabled: true,
    vestingContractJSON: vestingL1,
  },
  {
    poolName: 'Ingame',
    depositToGameEnabled: true,
    vestingContractJSON: vestingIngame,
  },
  {
    poolName: 'T',
    depositToGameEnabled: false,
    vestingContractJSON: vestingT,
  },
  {
    poolName: 'S1',
    depositToGameEnabled: true,
    vestingContractJSON: vestingS1,
  },
  {
    poolName: 'S2',
    depositToGameEnabled: true,
    vestingContractJSON: vestingS2,
  },
];
const activeAllocationPools: AllocationPoolConfig[] = [
  pregamePublicAllocationPoolUSDC,
  pregamePrivateAllocationPoolUSDC,
  // pregameSeedAllocationPoolUSDT,
];

export const baseConfig: AppConfig = {
  skynityFee: '0.3%',
  sdtRatio: 9,
  minimumDepositSdt: 20,
  minimumWithdrawalSdt: 20,
  withdrawalFeeFraction: 0.02,
  withdrawalFeeMinSdt: 1,
  permittedNetworks,
  primaryNetwork,
  tokens: {
    [MainTokenSymbol.SDT]: {
      name: TokenName.Skynity,
      symbol: MainTokenSymbol.SDT,
      decimals: 18,
      tokenContractJSON: SDTtokenContractJSON,
    },
    [LPTokenSymbol.SDTUSDC]: {
      name: TokenName.LPSkynity,
      symbol: LPTokenSymbol.SDTUSDC,
      decimals: 18,
      tokenContractJSON: SDTUSDCtokenLPContractJSON,
    },
    [StableTokenSymbol.USDC]: {
      name: TokenName.Stablecoin,
      symbol: StableTokenSymbol.USDC,
      decimals: 6,
      tokenContractJSON: SCUSDCTokenJSON,
    },
  },
  vesting: {
    pools: activeVestingPools,
  },
  staking: {
    pools: activeStakingPools,
  },
  allocation: {
    pools: activeAllocationPools,
  },
  gameBridgeContractJSON,
  nftContractJSON: NFTContactJSON,
  LPPairs: {
    // [LPTokenSymbol.SDTUSDT]: { contractJSON: SDTUSDTLPPairContractJSON },
    [LPTokenSymbol.SDTUSDC]: { contractJSON: SDTUSDCLPPairContractJSON },
  },
  additionalData: {
    tokenAddresses: {
      [MainTokenSymbol.SDT]: '0x04bc5918a08a526653e83420044b1a26ff34863b',
    },
    tokenChartLink: 'https://www.coingecko.com/pl/waluty/skynity-skydust',
    tokenLink:
      'https://app.uniswap.org/swap?chain=base&inputCurrency=0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913&outputCurrency=0x04bc5918a08A526653E83420044B1a26FF34863B',
    lpPairLink:
      'https://app.uniswap.org/explore/pools/base/0x546Cb0f1425DA6742fC559Fae14B045A94cD916d',
    skyLandsNftMarketplaceLink:
      'https://opensea.io/collection/skylands-skynity',
    skyLandsNftBridgeLink: 'https://app.mintlabz.io/bridge',
    tokenLinkCEX: 'https://www.mexc.com/exchange/SDT_USDT',
  },
};
