import BigNumber from 'bignumber.js';
import { ButtonWithSpinner, DataItem, Message } from 'components';
import {
  getVestingPoolData,
  MessageText,
  MessageType,
  RoutingPaths,
  vestingClaim,
} from 'helpers';
import {
  LPPairsContracts,
  MainTokenSymbol,
  TokensData,
  VestingPoolData,
} from 'models';
import React, { useEffect, useReducer } from 'react';
import {
  Card,
  Col,
  Container,
  ProgressBar,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getBalances, getLPsData, selectConnectedAddress } from 'store';

import { TruncatedNumber } from '../TruncatedNumber';
import {
  initialVestingPoolState,
  VestingPoolActions,
  vestingPoolReducer,
} from './store/reducer';

interface VestingPoolProps {
  tokensData: TokensData;
  LPPairsContracts?: LPPairsContracts;
  vestingPoolData: VestingPoolData;
}

// eslint-disable-next-line complexity
const VestingPool: React.FC<VestingPoolProps> = ({
  tokensData,
  LPPairsContracts,
  vestingPoolData,
}) => {
  const [state, dispatch] = useReducer(
    vestingPoolReducer,
    initialVestingPoolState
  );
  const dispatchApp = useDispatch();
  const navigate = useNavigate();
  const connectedAddress = useSelector(selectConnectedAddress);
  // const stableTokenPrices = useSelector(selectStableTokenPrices);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [state.loading]);

  const loadData = (): void => {
    if (!state.loading) {
      getVestingPoolDetailsData();
      dispatchApp(
        getBalances({
          tokensData: [tokensData.SDT],
          connectedAddress,
        })
      );
      // dispatchApp(
      //   getTokensPrices(stableTokenPrices, tokensData, stableTokenData, LPPairsData)
      // );

      dispatchApp(
        getLPsData({
          tokensData,
          LPPairsContracts,
        })
      );
    }
  };

  const getVestingPoolDetailsData = async (): Promise<void> => {
    try {
      const payload = await getVestingPoolData(
        connectedAddress,
        tokensData.SDT,
        vestingPoolData.vestingPoolContract
      );

      dispatch({
        type: VestingPoolActions.SetVesting,
        payload,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const amountLeftBN = new BigNumber(state.vesting?.amountLeft);
  const amountClaimableBN = new BigNumber(state.vesting?.amountClaimable);
  const amountAllocatedBN = new BigNumber(state.vesting?.amountAllocated);
  const percentLeft = amountLeftBN.isGreaterThan(0)
    ? +amountAllocatedBN.minus(amountLeftBN).div(amountAllocatedBN).times(100)
    : 100;
  const percentClaimable = +amountClaimableBN.div(amountAllocatedBN).times(100);

  const onClaim = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event.preventDefault();

    dispatch({ type: VestingPoolActions.SetLoading, payload: true });
    dispatch({ type: VestingPoolActions.SetErrorMessage, payload: '' });

    try {
      await vestingClaim(vestingPoolData, connectedAddress);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch({
        type: VestingPoolActions.SetErrorMessage,
        payload: error.message,
      });
    }

    dispatch({ type: VestingPoolActions.SetLoading, payload: false });
  };

  const onTransferIntoGame = (): void => {
    navigate(RoutingPaths.Settings);
  };

  return (
    <Card className="vesting-pool">
      {state.vesting && (
        <>
          <div className="vesting-pool-name">{vestingPoolData.poolName}</div>
          <div className="vesting-pool-details">
            <DataItem
              label="Total allocated"
              data={state.vesting.amountAllocated}
              symbol={MainTokenSymbol.SDT}
              iconClasses="fas fa-bank"
            />
            <DataItem
              label="Total claimed"
              tooltipHint={
                <Container>
                  <Row>
                    <Col>Claimed to wallet</Col>
                    <Col>Deposited into game</Col>
                  </Row>
                  <Row className="fw-bold">
                    <Col>{state.vesting.amountClaimed}</Col>
                    <Col>{state.vesting.amountClaimedToBridge}</Col>
                  </Row>
                </Container>
              }
              iconClasses="fas fa-piggy-bank"
            >
              <TruncatedNumber value={state.vesting.amountClaimedTotal} />{' '}
              {MainTokenSymbol.SDT}
            </DataItem>
            <DataItem
              label="Total left"
              data={state.vesting.amountLeft}
              symbol={MainTokenSymbol.SDT}
              iconClasses="fas fa-sack-dollar"
            />
            <DataItem
              label="Claimable"
              iconClasses="fas fa-arrow-right-from-bracket"
            >
              {amountLeftBN.isGreaterThan(0) ? (
                <>
                  <TruncatedNumber value={state.vesting.amountClaimable} />{' '}
                  {MainTokenSymbol.SDT}
                </>
              ) : (
                <span>All claimed</span>
              )}
            </DataItem>
          </div>

          <div className="vesting-pool-progress-container">
            <div className="vesting-pool-progress">
              <ProgressBar>
                <ProgressBar
                  now={percentLeft}
                  key={1}
                  title="Total claimed"
                  data-bs-toggle="tooltip"
                  data-bs-title="Default tooltip"
                />
                <ProgressBar
                  striped
                  now={percentClaimable}
                  key={2}
                  title="Claimable"
                  className="progress-bar-animated opacity-50"
                />
              </ProgressBar>
            </div>
            <>
              {amountClaimableBN.isEqualTo(0) &&
                amountLeftBN.isGreaterThan(0) && (
                  <Message
                    messageType={MessageType.Primary}
                    descriptionText={MessageText.NothingToClaim}
                  />
                )}

              {/* {amountClaimableBN.isGreaterThan(0) && (
                <Message
                  messageType={MessageType.Primary}
                  descriptionText={MessageText.Fees}
                />
              )} */}
            </>
          </div>

          {amountLeftBN.isGreaterThan(0) && (
            <>
              {vestingPoolData.depositToGameEnabled && (
                <ButtonWithSpinner
                  text="Deposit into game"
                  classes="btn-primary vesting-pool-button"
                  onClick={(): void => onTransferIntoGame()}
                  disabled={amountLeftBN.isEqualTo(0) || state.loading}
                  loading={state.loading}
                />
              )}
              <ButtonWithSpinner
                text="Claim to wallet"
                classes="btn-secondary vesting-pool-button"
                onClick={(event): Promise<void> => onClaim(event)}
                disabled={amountClaimableBN.isEqualTo(0) || state.loading}
                loading={state.loading}
              />
            </>
          )}
        </>
      )}
      {!state.vesting && (
        <div className="spinner-wrapper">
          <Spinner animation="border" role="status" className="gold" />
        </div>
      )}
    </Card>
  );
};

export default VestingPool;
