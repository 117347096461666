import metamaskFox from 'assets/svg/metamask_fox.svg';
import { addTokenToWallet, skynityConfig } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectConnectedNetwork, selectWeb3 } from 'store';
import BuyButton from '../BuyButton';

export const Hero: React.FC = () => {
  const connectedNetwork = useSelector(selectConnectedNetwork);
  const web3 = useSelector(selectWeb3);

  const [tokenAddress, setTokenAddress] = useState<string>();
  const [nftAddress, setNftAddress] = useState<string>();

  useEffect(() => {
    if (connectedNetwork) {
      setTokenAddress(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (skynityConfig.tokens.SDT.tokenContractJSON.networks as any)[
          connectedNetwork.chainId
        ]?.address
      );
      setNftAddress(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (skynityConfig.nftContractJSON.networks as any)[
          connectedNetwork.chainId
        ]?.address
      );
    }
  }, [connectedNetwork]);

  return (
    <div className="hero">
      <div className="container text-center">
        <div className="hero-wrapper">
          <h1 className="hero-header mb-24">
            Welcome to <div className="font-bold">SkyNity</div>
          </h1>

          <h3 className="hero-description">Get ready to fight in the sky</h3>

          <div className="hero-buttons-container">
            <a
              href="https://skynity.gitbook.io/wiki"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              Game Wiki
            </a>

            {/* TODO: Update or remove */}
            {/* <Link to={RoutingPaths.PrivateSale} className="btn btn-primary">
              Private Sale
            </Link>
            <Link
              to={RoutingPaths.Staking}
              className="btn btn-primary inactive"
            >
              Stake
            </Link> */}
          </div>

          {connectedNetwork && (
            <h3 className="hero-description-2 hero-tokens-info">
              <div>SkyDust SDT token contract address:</div>
              <div className="text-truncate w-100">
                <span className="bold mr-8">{tokenAddress}</span>
                <div className="nowrap d-sm-inline d-block">
                  <a
                    className="link fa-regular fa-copy hero-tokens-info-icon"
                    onClick={(): void => {
                      navigator.clipboard.writeText(tokenAddress);
                    }}
                    title="Copy to clipboard"
                  ></a>
                  &nbsp;
                  <a
                    className="metamask-button link hero-tokens-info-icon"
                    title="Add to metamask"
                    onClick={(): void => {
                      addTokenToWallet(web3, connectedNetwork.chainId);
                    }}
                  >
                    <img src={metamaskFox} className=""></img>
                  </a>
                  &nbsp;
                  <a
                    href={skynityConfig.additionalData.tokenChartLink}
                    target="_blank"
                    rel="noreferrer"
                    title="View on CoinGecko"
                    className="link fa-solid fa-chart-line hero-tokens-info-icon"
                  ></a>
                  &nbsp;
                  {/* <a
                    href={skynityConfig.additionalData.tokenLinkCEX}
                    target="_blank"
                    rel="noreferrer"
                    title="Buy on MEXC (no KYC)"
                    className="link fa-solid fa-cart-shopping hero-tokens-info-icon"
                  ></a> */}
                  <BuyButton className="hero-tokens-info-icon" />
                </div>
              </div>
              <div>SkyLands NFT collection address:</div>
              <div className="text-truncate w-100">
                <span className="bold mr-8">{nftAddress}</span>
                <div className="nowrap d-sm-inline d-block">
                  <a
                    className="link fa-regular fa-copy hero-tokens-info-icon"
                    onClick={(): void => {
                      navigator.clipboard.writeText(nftAddress);
                    }}
                    title="Copy to clipboard"
                  ></a>
                  &nbsp;
                  <a
                    href={
                      skynityConfig.additionalData.skyLandsNftMarketplaceLink
                    }
                    target="_blank"
                    rel="noreferrer"
                    title="Trade on NFT marketplace"
                    className="link fa-solid fa-cart-shopping hero-tokens-info-icon"
                  ></a>
                  &nbsp;
                  <a
                    href={skynityConfig.additionalData.skyLandsNftBridgeLink}
                    target="_blank"
                    rel="noreferrer"
                    title="Bridge between networks"
                    className="link fa-solid fa-right-left hero-tokens-info-icon"
                  ></a>
                </div>
                &nbsp;
              </div>
            </h3>
          )}
        </div>
      </div>
    </div>
  );
};
