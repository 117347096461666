import { DataItem, SectionHeader } from 'components';
import { capitalize } from 'lodash';
import { GameServer, GameServerStatus } from 'models';
import React from 'react';

export interface GameServerCardProps {
  selectedGameServer: GameServer;
  onLogout: () => void;
}

export const GameServerCard: React.FC<GameServerCardProps> = ({
  selectedGameServer,
  onLogout,
}) => (
  <div className="section-container game-server">
    <SectionHeader
      title={'Game server'}
      description={'Below are displayed basic game server details'}
    />

    <div className="game-server-data-items">
      <DataItem
        iconClasses={'fa fa-server'}
        label={'Server name'}
        data={selectedGameServer.name}
      />
      {selectedGameServer.status === GameServerStatus.Finished ? (
        <DataItem
          iconClasses="fa fa-info"
          label={'Status'}
          data={`${capitalize(selectedGameServer.status)}`}
        />
      ) : (
        <DataItem
          iconClasses="fa fa-users"
          label={'Players'}
          data={`${selectedGameServer.stats.players} / ${selectedGameServer.players_limit}`}
        />
      )}
    </div>

    <button
      className="btn btn-secondary nowrap mt-32 w-100"
      onClick={(): void => onLogout()}
    >
      <i className="fa fa-power-off pointer mr-8" />
      Logout
    </button>
  </div>
);
