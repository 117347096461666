import { ButtonWithSpinner, Message, NftItem } from 'components';
import { isUnassignedNft, MessageType } from 'helpers';
import { GameAccount, GameServer, Nft } from 'models';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { createGameAccount, resetGameSettingsErrors } from 'store';

export interface CreateGameAccountModalProps {
  nfts: Nft[];
  gameAccounts: GameAccount[];
  gameServers: GameServer[];
  error: string;
  hideModal: () => void;
}

export const CreateGameAccountModal: React.FC<CreateGameAccountModalProps> = ({
  nfts,
  gameAccounts,
  gameServers,
  error,
  hideModal,
}) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [accountNft, setAccountNft] = useState<Nft>(null);
  const [loading, setLoading] = useState<boolean>(null);

  useEffect(() => {
    if (loading) {
      onHideModal();
    }
  }, [gameAccounts]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  const unassignedNftItems = nfts?.reduce((acc: JSX.Element[], nft: Nft) => {
    const isUnassigned = isUnassignedNft(gameServers, nft);

    if (isUnassigned) {
      acc.push(
        <NftItem
          key={`nft-item-${nft.edition}`}
          nft={nft}
          onClick={(): void => setAccountNft(nft)}
          selected={accountNft?.edition === nft.edition}
        />
      );
    }

    return acc;
  }, []);

  const onHideModal = (): void => {
    setLoading(false);
    dispatch(resetGameSettingsErrors());
    hideModal();
  };

  const onCreateAccount = (): void => {
    setLoading(true);
    dispatch(resetGameSettingsErrors());
    dispatch(
      createGameAccount({
        accountName: username,
        accountPassword: password,
        nftId: accountNft.edition,
      })
    );
  };

  return (
    <Modal
      show={true}
      onHide={(): void => onHideModal()}
      backdrop="static"
      centered
      className="create-game-account-modal"
    >
      <Modal.Header className="justify-center">Create new account</Modal.Header>
      <Modal.Body>
        <div>
          <label className="s-input-label">Username</label>
          <input
            className="s-input w-100 round"
            placeholder="Username"
            onChange={(event): void =>
              setUsername(event.target.value?.replace(/\s/g, ''))
            }
            value={username}
            disabled={loading}
          />
        </div>

        <div className="mb-3 position-relative">
          <label className="s-input-label mt-24">Password</label>
          <div className="position-relative">
            <input
              type={isVisible ? 'text' : 'password'}
              className="s-input w-100 round"
              placeholder="Password"
              onChange={(event): void => setPassword(event.target.value)}
              value={password}
              disabled={loading}
            />
            <button
              type="button"
              className="btn position-absolute end-0 top-50 translate-middle-y bg-transparent border-0 px-3"
            >
              <i
                className={`toggle-password-visibility-icon fas ${
                  isVisible ? 'fa-eye-slash' : 'fa-eye'
                } text-muted`}
                onClick={(): void => setIsVisible(!isVisible)}
              />
            </button>
          </div>
        </div>

        <div>
          <label className="s-input-label mt-24">Choose SkyLand NFT</label>

          {nfts?.length ? (
            <div className="nft-items">{unassignedNftItems}</div>
          ) : (
            ''
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithSpinner
          classes="btn btn-primary w-100"
          loading={loading}
          onClick={(): void => onCreateAccount()}
          disabled={!username || !password || !accountNft || loading}
        >
          Create account
        </ButtonWithSpinner>

        <button
          className="btn btn-secondary w-100 mt-16"
          onClick={(): void => onHideModal()}
          disabled={loading}
        >
          Close
        </button>

        {error ? (
          <div className="w-100">
            <Message
              messageType={MessageType.Error}
              descriptionText={error}
            ></Message>
          </div>
        ) : (
          ''
        )}
      </Modal.Footer>
    </Modal>
  );
};
